import {
  BrandsStrip,
  CardBlock,
  EventsGrid,
  FeaturedSection,
  FeaturedSectionColumns,
  FooterCTA,
  FormAttendV3,
  FormSponsorV3,
  FormContactUsV3,
  HeroBrand,
  Hero,
  Link,
  LinkBox,
  LinkBoxNOTarget,
  LogosStrip,
  Offices,
  SectionCallout,
  SEO,
  SocialShare,
  Utils,
  ActionButton,
  ActionButtonAlt,
} from "Components";
import Config from "Data";
import {graphql} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import Layout from "Layouts/default";
import moment from "moment";
import React from "react";
import Notifications, {notify} from "react-notify-toast";
import Fade from "react-reveal/Fade";
import styled from "styled-components";
import {Brands, Content, Gap, Partners, Wrapper} from "Styles";
import {theme} from "Theme";

const TitlesGrid = styled.div`
    margin: 0rem auto;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    @media (min-width: ${theme.breakpoints.s}) {
        grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
    }
    @media (min-width: ${theme.breakpoints.m}) {
    }
    @media (min-width: ${theme.breakpoints.l}) {
        grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));
    }
    grid-gap: 1rem;
    transition: all 0.3s cubic-bezier(0.55, 0.06, 0.68, 0.19);
`;
const TitlesGridFull = styled.div`
    margin: 1rem auto;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        /* @media (min-width: ${theme.breakpoints.s}) {
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
    grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));
  } */
    grid-gap: 1rem;
`;
const TitleBlock = styled.div`
    a {
        width: 100%;
        transition: 0.3s;

        &:hover,
        &:focus {
            transform: scale(1.02);
            box-shadow: ${theme.shadow.feature.small.hover};
        }
    }
`;

const TitlesTitle = styled.h2`
    color: inherit;
    margin: 0;

    p {
        margin: 0;
    }
`;

const BrandedColor = styled.span`
    color: ${({color}) =>
            color ? `${Utils.BrandColor(color)}` : `${theme.colors.primary.base}`};
`;

const TitlesDesc = styled.p`
    color: inherit;
    font-size: ${theme.fontSize.small};
    margin: 0;
`;

const ActionsBlock = styled.div`
    text-align: center;
    background: #fff;
    border-radius: ${theme.borderRadius.ui};
    box-shadow: ${theme.shadow.feature.small.default};
    @media (min-width: ${theme.breakpoints.s}) {
    }
    @media (min-width: ${theme.breakpoints.m}) {
        display: grid;
        align-content: center;
    }
    @media (min-width: ${theme.breakpoints.l}) {
    }
    transition: 0.3s;

    &:hover,
    &:focus {
        transform: scale(1.04);
        box-shadow: ${theme.shadow.feature.small.hover};
    }
`;

const Grid = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: ${theme.layout.base};
`;

const SectionContent = styled.div`
    direction: ${(props) => (props.dir === "rtl" ? "rtl;" : "ltr;")};
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    @media (min-width: ${theme.breakpoints.s}) {
    }
    @media (min-width: ${theme.breakpoints.m}) {
        grid-template-columns: 2fr 33%;
    }
    @media (min-width: ${theme.breakpoints.l}) {
    }
    grid-gap: 1rem;
    margin: 0 auto;
`;

const BrandDescription = styled.div`
    direction: ltr;
    text-align: ${(props) => (props.dir === "rtl" ? "right;" : "left;")};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
        font-size: 2.4rem;
        margin-bottom: 2.4rem;
    }

    h3 {
        font-size: 1.2rem;
        margin-bottom: 1.2rem;
    }

    padding: 2rem 1rem;
    margin: 4rem auto 0;
    @media (min-width: ${theme.breakpoints.s}) {
    }
    @media (min-width: ${theme.breakpoints.m}) {
        max-width: 60%;
        margin: 4rem 0;
        padding: 0 0;
    }
    @media (min-width: ${theme.breakpoints.l}) {
    }
`;

const BrandsGrid = styled.div`
    margin: 2rem auto 6rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    @media (min-width: ${theme.breakpoints.s}) {
    }
    @media (min-width: ${theme.breakpoints.m}) {
        grid-template-columns: 1fr;
        margin: 4rem auto;
    }
    @media (min-width: ${theme.breakpoints.l}) {
        grid-template-columns: 1fr 1fr;
    }
    grid-gap: 1rem;
`;

const StyledNotificationButton = styled((props) => <Link {...props} />)`
    color: ${theme.colors.white.base};
    font-size: ${theme.fontSize.small};
    cursor: pointer;
    font-weight: bold;
    white-space: nowrap;
    display: block;
    border-radius: ${theme.borderRadius.elements};
    padding: 12px 18px;
    background: ${theme.colors.primary.base};
    border-width: 1px;
    border-color: ${theme.colors.primary.base};
    border-style: solid;
    width: 100%;
    text-align: inherit;
    margin: 6px;

    span {
        padding: 1rem !important;
    }

    &:hover,
    &:focus {
        color: ${theme.colors.white.base};
        background: ${theme.colors.primary.dark};
        border-color: ${theme.colors.primary.base};
        outline: 0;
        box-shadow: ${theme.shadow.feature.small.hover};
    }

    @media (min-width: ${theme.breakpoints.m}) {
        margin: 0 0 0 6px;
        padding: 6px 18px;
        font-size: ${theme.fontSize.smallish};
        width: auto;
        display: inline-block;
        text-align: left;
    }
    @media (min-width: ${theme.breakpoints.l}) {
    }
`;

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentModal: null,
      shareEvent: null,
      showEvents: false,
    };
  }

  componentDidMount() {
    let CXOsyncColors = {
      background: `${theme.colors.white.base}`,
      text: `${theme.colors.primary.base}`,
    };
    this.setState({
      currentModal: null,
      shareEvent: null,
      showEvents: false,
    });
    // notify.show(
    //   <div>
    //     Novel Coronavirus (COVID-19) Update{" "}
    //     <StyledNotificationButton to={"/resources/covid19"} target={"_blank"} track={"COVID-19 Link"}>
    //       read more.
    //     </StyledNotificationButton>
    //   </div>,
    //   "custom",
    //   -1,
    //   CXOsyncColors
    // );
  }

  toggleModal = (key, param) => (e) => {
    e.preventDefault();
    if (this.state.currentModal) {
      this.handleModalCloseRequest();
      return;
    }

    this.setState({
      ...this.state,
      currentModal: key,
      shareEvent: param.shareEvent,
      showEvents: param.showEvents,
    });
  };

  handleModalCloseRequest = () => {
    this.setState({
      ...this.state,
      currentModal: null,
    });
  };

  handleOnAfterOpenModal = () => {
  };

  render() {
    const {data} = this.props;
    // console.log(data);
    const hero = data.hero.edges[0].node;
    const backgrounds = data.backgrounds;
    const featured = data.featured.edges[0].node;
    const featuredAlt = data.featuredAlt.edges[0].node;
    const triangle = data.triangle;
    const device = data.device;
    const devices = data.devices;
    const mapSection = data.about.mapSection;
    const offices = data.offices.edges;

    const cio = data.cio.edges;
    const ciso = data.ciso.edges;
    const cmo = data.cmo.edges;
    const cfo = data.cfo.edges;
    const eps_logo = data.eps_logo;
    const eps_bg = data.eps_bg;

    // console.log({ data })
    let allEventsData = [].concat(
      data.events2020.edges,
      data.events2021.edges,
      data.events2022.edges,
      data.events2023.edges,
      data.events2024.edges,
      data.events2025.edges
    );
    let allEvents = allEventsData.sort((a, b) =>
      a.node.date > b.node.date ? 1 : -1
    );

    let futureEvents = allEvents.filter((event) => {
      return !moment(event.node.date).isBefore(moment(), "day");
    });
    futureEvents.sort((a, b) => (a.node.date > b.node.date ? 1 : -1));

    // console.log({ allEvents })
    // console.log({ futureEvents })
    const partners = data.partners_data;
    const brands = data.brands.edges;
    const {currentModal} = this.state;

    return (
      <Layout
        locale={"en-us"}
        region={"us"}
        sticky={true}
        transparentHeader={false}
        transparentFooter={false}
        mode={"light"}
        path={this.props.path}
      >
        <SEO
          pathname="/"
          title={`CXO Events | ${Config.brandBusiness} by ${Config.contact.name}`}
          description={
            `We host live and virtual events to gather CXOs from the world's largest corporations and brands. From our global conferences, private dinners and virtual events; CXOs gather to discuss, debate and challenge issues and strategies - a brain-trust built on thought-leading content` ||
            ""
          }
          keywords={"pageKeywords"}
          banner={
            hero.childrenFile[0].childImageSharp.gatsbyImageData.images
              .sources[0].srcSet || ""
          }
          events={futureEvents}
        />
        <SocialShare
          pathname="/"
          title={`CXO Events | ${Config.brandBusiness} by ${Config.contact.name}`}
          description={
            `We host live and virtual events to gather CXOs from the world's largest corporations and brands. From our global conferences, private dinners and virtual events; CXOs gather to discuss, debate and challenge issues and strategies - a brain-trust built on thought-leading content` ||
            ""
          }
          banner={
            hero.childrenFile[0].childImageSharp.gatsbyImageData.images
              .sources[0].srcSet || ""
          }
        />

        <Notifications options={{zIndex: 9, top: "80px"}}/>
        {/* {console.log("Parth", backgrounds)} */}
        <Hero
          titles={true}
          height={60}
          justify={"end"}
          title={"We collaborate"}
          subtitle={`We host live and virtual events to gather CXOs from the world's largest corporations and brands. From our global conferences, private dinners and virtual events; CXOs gather to discuss, debate and challenge issues and strategies - a brain-trust built on thought-leading content`}
          color={Config.brand}
          backgrounds={backgrounds}
          backgroundsArray={[
            "CIOLondon-ciosynergy",
            "20180315_100514",
            "20180726_111248",
            "20190124_101336",
          ]}
          track={"Hero"}
        />

        <div>
          <SectionCallout
            hero={true}
            size={"medium"}
            dark={false}
            grid={true}
            overflow={true}
          >
            <TitlesGrid>
              <Fade top duration={800} delay={800}>
                <TitleBlock>
                  <LinkBox
                    section_ref={true}
                    to={`#cio`}
                    track={`Homepage Featured Section`}
                    title={`CIO Events`}
                    offset="180"
                  >
                    <CardBlock color="ciosynergy">
                      <TitlesTitle>For CIOs</TitlesTitle>
                      <TitlesDesc>Chief Information Officer Events</TitlesDesc>
                    </CardBlock>
                  </LinkBox>
                </TitleBlock>
              </Fade>

              <Fade top duration={800} delay={1000}>
                <TitleBlock>
                  <LinkBox
                    section_ref={true}
                    to={`#ciso`}
                    track={`Homepage Featured Section`}
                    title={`CISO Events`}
                    offset="180"
                  >
                    <CardBlock color="secureciso">
                      <TitlesTitle>For CISOs</TitlesTitle>
                      <TitlesDesc>Chief Information Security Events</TitlesDesc>
                    </CardBlock>
                  </LinkBox>
                </TitleBlock>
              </Fade>

              <Fade top duration={800} delay={1200}>
                <TitleBlock>
                  <LinkBox
                    section_ref={true}
                    to={`#cmo`}
                    track={`Homepage Featured Section`}
                    title={`CMO Events`}
                    offset="180"
                  >
                    <CardBlock color="cmo.org">
                      <TitlesTitle>For CMOs</TitlesTitle>
                      <TitlesDesc>Chief Marketing Officer Events</TitlesDesc>
                    </CardBlock>
                  </LinkBox>
                </TitleBlock>
              </Fade>

              <Fade top duration={800} delay={1300}>
                <TitleBlock>
                  <LinkBox
                    section_ref={true}
                    to={`#cfo`}
                    track={`Homepage Featured Section`}
                    title={`CFO Events`}
                    offset="180"
                  >
                    <CardBlock color="cfo.org">
                      <TitlesTitle>For CFOs</TitlesTitle>
                      <TitlesDesc>Chief Financial Officer Events</TitlesDesc>
                    </CardBlock>
                  </LinkBox>
                </TitleBlock>
              </Fade>
            </TitlesGrid>
            {/* <TitlesGridFull>
            <Fade bottom duration={800} delay={1000}>
              <TitleBlock>
                <div offset="180">
                  <CardBlock color="eps">
                    <TitlesTitle>Missing CXOs and key decision makers at your events?</TitlesTitle>
                    <TitlesDesc>
                      We populate events for the world's largest brands using the tools & relationships tuned from 15
                      years of CIOsynergy, secureCISO, CMO.org, CFO.org & CXOsync private events.
                    </TitlesDesc>
                    <ActionButton
                      style={{ width: "auto", marginTop: "1rem" }}
                      to={`https://www.eventpopulation.com/`}
                      track={"Hero Bottom"}
                      title={`${`Missing CXOs and key decision makers at your events?`} | ${`Visit Site`}`}>
                      {`Visit Site`}
                    </ActionButton>
                    <ActionButtonAlt
                      style={{ width: "auto" }}
                      to={`https://cxo.typeform.com/to/ub8GOw`}
                      track={"Hero Bottom"}
                      title={`${`Missing CXOs and key decision makers at your events?`} | ${`Request a quote`}`}>
                      {`Request a quote`}
                    </ActionButtonAlt>
                  </CardBlock>
                </div>
              </TitleBlock>

            </Fade>
          </TitlesGridFull> */}
          </SectionCallout>

          {featured && (
            <FeaturedSection
              dark={false}
              title={`Meet CXOsync`}
              subtitle={`We've mastered CXO collaboration through thought leading content and audience engineering. Established in 2008 CXOsync has planned, populated and executed thousands of CXO events globally; events that collaborate corporate leaders with cutting edge content & solutions in the fields of IT, Information Security, Marketing, Finance, Human Resources and Customer Experience.`}
              image={
                featured.childrenFile[0].childImageSharp.gatsbyImageData || ""
              }
              cta_url1_text={"Upcoming Events"}
              cta_url1={"/events/"}
              track={"Featured Section"}
            />
          )}
          {/* <FeaturedSectionColumns /> */}
          <SectionCallout
            size={"big"}
            dark={false}
            grid={true}
            page={this.props}
            background={triangle.edges[0].node.childImageSharp.gatsbyImageData}
          >
            <SectionContent dir="ltr">
              <BrandDescription dir="ltr" id="cio">
                <h2>
                  <BrandedColor color="ciosynergy">CIO</BrandedColor> Events
                </h2>
                <h3>CIOsynergy, fintechCIO, CIOhealth &amp; CIOdinner</h3>
                <p>
                  IT leaders from within the Office of the CIO for the largest
                  enterprise and brands gather to collaborate on strategies and
                  directions.
                </p>
              </BrandDescription>
              <BrandsGrid>
                {cio.map((brand, i) => {
                  return (
                    <LinkBox
                      key={i}
                      to={brand.node.website}
                      alt={`${brand.node.title}`}
                      title={`${brand.node.title}`}
                      target="_blank"
                      track={"Brand Link"}
                      rel="nofollow noopener noreferrer"
                      aria-label={`${brand.node.title}`}
                    >
                      <Fade
                        right
                        duration={(i + 1) * 200}
                        delay={(i + 1) * 200}
                      >
                        <ActionsBlock>
                          <Brands.Logo>
                            <GatsbyImage
                              alt={""}
                              title={`${brand.node.title}`}
                              image={
                                brand.node.childrenFile[0].childImageSharp
                                  .gatsbyImageData
                              }
                              objectFit="contain"
                            />
                          </Brands.Logo>
                        </ActionsBlock>
                      </Fade>
                    </LinkBox>
                  );
                })}
              </BrandsGrid>
            </SectionContent>
            <SectionContent dir="rtl">
              <BrandDescription dir="rtl" id="ciso">
                <h2>
                  <BrandedColor color="secureciso">CISO</BrandedColor> Events
                </h2>
                <h3>secureCISO, fintechCISO &amp; CISOdinners</h3>
                <p>
                  Infosec leaders gather in numbers to strategize digital
                  defense. Are these leaders detecting or mitigating as their
                  main defense against intrusion?
                </p>
              </BrandDescription>
              <BrandsGrid>
                {ciso.map((brand, i) => {
                  return (
                    <LinkBox
                      key={i}
                      to={brand.node.website}
                      alt={`${brand.node.title}`}
                      title={`${brand.node.title}`}
                      target="_blank"
                      track={"Brand Link"}
                      rel="nofollow noopener noreferrer"
                      aria-label={`${brand.node.title}`}
                    >
                      <Fade
                        bottom
                        duration={(i + 1) * 200}
                        delay={(i + 1) * 200}
                      >
                        <ActionsBlock>
                          <Brands.Logo>
                            <GatsbyImage
                              alt={""}
                              title={`${brand.node.title}`}
                              image={
                                brand.node.childrenFile[0].childImageSharp
                                  .gatsbyImageData
                              }
                              objectFit="contain"
                            />
                          </Brands.Logo>
                        </ActionsBlock>
                      </Fade>
                    </LinkBox>
                  );
                })}
              </BrandsGrid>
            </SectionContent>
            <SectionContent dir="ltr">
              <BrandDescription dir="ltr" id="cmo">
                <h2>
                  <BrandedColor color="cmo.org">CMO</BrandedColor> Events
                </h2>
                <h3>CMO.org, CMOdinners &amp; MERCHANT.org</h3>
                <p>
                  No other position has evolved as aggressively as that of the
                  CMO. We gather marketing leaders from the biggest brands to
                  share those ever important lessons learned.
                </p>
              </BrandDescription>
              <BrandsGrid>
                {cmo.map((brand, i) => {
                  return (
                    <LinkBox
                      key={i}
                      to={brand.node.website}
                      alt={`${brand.node.title}`}
                      title={`${brand.node.title}`}
                      target="_blank"
                      track={"Brand Link"}
                      rel="nofollow noopener noreferrer"
                      aria-label={`${brand.node.title}`}
                    >
                      <Fade left duration={(i + 1) * 200} delay={(i + 1) * 200}>
                        <ActionsBlock>
                          <Brands.Logo>
                            <GatsbyImage
                              alt={`${brand.node.title}`}
                              title={`${brand.node.title}`}
                              image={
                                brand.node.childrenFile[0].childImageSharp
                                  .gatsbyImageData
                              }
                              objectFit="contain"
                            />
                          </Brands.Logo>
                        </ActionsBlock>
                      </Fade>
                    </LinkBox>
                  );
                })}
              </BrandsGrid>
            </SectionContent>
            <SectionContent dir="rtl">
              <BrandDescription dir="rtl" id="cfo">
                <h2>
                  <BrandedColor color="cfo.org">CFO</BrandedColor> Events
                </h2>
                <h3>CFO.org &amp; CFOdinners</h3>
                <p>
                  Financial leaders from the globes biggest brands collaborate
                  with us. CFOs here have a story to tell as the walk the line
                  of financial leadership in the digital age.
                </p>
              </BrandDescription>
              <BrandsGrid>
                {cfo.map((brand, i) => {
                  return (
                    <LinkBox
                      key={i}
                      to={brand.node.website}
                      alt={`${brand.node.title}`}
                      title={`${brand.node.title}`}
                      target="_blank"
                      track={"Brand Link"}
                      rel="nofollow noopener noreferrer"
                      aria-label={`${brand.node.title}`}
                    >
                      <Fade top duration={(i + 1) * 200} delay={(i + 1) * 200}>
                        <ActionsBlock>
                          <Brands.Logo>
                            <GatsbyImage
                              alt={`${brand.node.title}`}
                              title={`${brand.node.title}`}
                              image={
                                brand.node.childrenFile[0].childImageSharp
                                  .gatsbyImageData
                              }
                              objectFit="contain"
                            />
                          </Brands.Logo>
                        </ActionsBlock>
                      </Fade>
                    </LinkBox>
                  );
                })}
              </BrandsGrid>
            </SectionContent>
            <Gap/>
          </SectionCallout>

          {/* <SectionCallout
          size={"big"}
          dark={true}
          image={eps_bg.edges[0].node.childImageSharp.gatsbyImageData}
          logo={eps_logo.edges[0].node.childImageSharp.gatsbyImageData}
          title={`Missing CXOs and key decision makers at your events?`}
          subtitle={`We populate events for the world's largest brands using the tools & relationships tuned from 11 years of CIOsynergy, secureCISO, CMO.org, CFO.org & CXOsync private events.`}
          cta_url1_text={`Visit Site`}
          cta_url1={`https://www.eventpopulation.com/`}
          cta_url2_text={`Request a quote`}
          cta_url2={`https://cxo.typeform.com/to/ub8GOw`}
          track={"Callout Top"}
        /> */}

          <LogosStrip dark={false}>
            <Wrapper>
              <Grid>
                <Partners.Grid>
                  {partners.edges.map((partner, i) => {
                    return (
                      <Partners.Logo key={i}>
                        <GatsbyImage
                          alt={`${partner.node.name} logo`}
                          title={`${partner.node.name} logo`}
                          image={
                            partner.node.childrenFile[0].childImageSharp
                              .gatsbyImageData
                          }
                          objectFit="contain"
                          objectPosition="50% 50%"
                        />
                      </Partners.Logo>
                    );
                  })}
                </Partners.Grid>
              </Grid>
            </Wrapper>
          </LogosStrip>

          <Gap/>

          <SectionCallout
            small={true}
            dark={false}
            grid={true}
            page={this.props}
            background={triangle.edges[0].node.childImageSharp.gatsbyImageData}
          >
            <Gap/>
            <EventsGrid
              button={true}
              countdown={false}
              events={futureEvents}
              coming={true}
              show={6}
              featured={false}
              big={true}
              filter={false}
              header={true}
              now={false}
              title={`Upcoming events`}
              page={this.props}
              item_action={this.toggleModal("modal_contactus", {
                shareEvent: true,
                showEvents: true,
              })}
            />

            <Gap/>
            {/* <SectionTweets title={`What CXOsync Leaders Are Saying`} data={tweetsSection} tweets={tweets} /> */}
            <Gap/>
          </SectionCallout>

          <SectionCallout
            size={"big"}
            dark={true}
            image={device.edges[0].node.childImageSharp.gatsbyImageData}
            logo={devices.edges[0].node.childImageSharp.gatsbyImageData}
            logoSize={"280px"}
            title={`CXOcircle`}
            subtitle={`Download the CXOcircle app by CXOsync. Join the most powerful network to enhance your career.`}
            cta_url1_text={"Visit Site"}
            cta_url1={"https://cxocircle.com"}
            track={"Callout Top"}
          />
        </div>
        <Gap/>

        {/*<SectionCallout track={"Offices & Map Section"}>*/}
        {/*  <Gap/>*/}
        {/*  <Offices data={offices}/>*/}
        {/*</SectionCallout>*/}

        <FooterCTA
          dark={false}
          title="Got questions?"
          subtitle={`Get in touch directly by sending us an email at ${Config.contact.email} or give us a call:`}
          cta_url1={`tel:${Config.contact.phone.us}`}
          cta_url1_text={`${Config.contact.phone.us}`}
          cta_url2={`tel:${Config.contact.phone.uk}`}
          cta_url2_text={`${Config.contact.phone.uk}`}
          page={this.props}
        />

        <BrandsStrip dark={false} brands={brands}/>

        {/*<Wrapper>*/}
        {/*  <Content>*/}
        {/*    <GatsbyImage*/}
        {/*      alt={`${Config.contact.name} Offices`}*/}
        {/*      title={`${Config.contact.name} Offices`}*/}
        {/*      image={*/}
        {/*        mapSection.image.childImageSharp.gatsbyImageData ||*/}
        {/*        {} ||*/}
        {/*        [] ||*/}
        {/*        ""*/}
        {/*      }*/}
        {/*      layout="fullWidth"*/}
        {/*      formats={["auto", "webp", "avif"]}*/}
        {/*      style={{*/}
        {/*        gridArea: "1/1",*/}
        {/*        // You can set a maximum height for the image, if you wish.*/}
        {/*        // maxHeight: 600,*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </Content>*/}
        {/*</Wrapper>*/}

        <FooterCTA
          dark={true}
          title={`Interested in attending one of our upcoming events?`}
          subtitle={`Find your seat at the table in a city near you`}
          cta_url1_text={`Events Calendar`}
          cta_url1={"/events/"}
          track={"Footer CTA"}
        />

        <FormAttendV3
          events={futureEvents}
          showEvents={this.state.showEvents}
          isOpen={currentModal === "modal_attend"}
          onAfterOpen={this.handleOnAfterOpenModal}
          onRequestClose={this.handleModalCloseRequest}
          closeModal={this.toggleModal("modal_attend", {
            shareEvent: null,
            showEvents: false,
          })}
        />

        <FormSponsorV3
          events={futureEvents}
          showEvents={this.state.showEvents}
          options={false}
          optionsLink={false}
          isOpen={currentModal === "modal_sponsor"}
          onAfterOpen={this.handleOnAfterOpenModal}
          onRequestClose={this.handleModalCloseRequest}
          closeModal={this.toggleModal("modal_sponsor", {
            shareEvent: null,
            showEvents: false,
          })}
        />
        <FormContactUsV3
          title={`Have a question for the ${Config.brand} team?`}
          subtitle="Call us or submit below:"
          isOpen={currentModal === "modal_contactus"}
          onAfterOpen={this.handleOnAfterOpenModal}
          onRequestClose={this.handleModalCloseRequest}
          closeModal={this.toggleModal("modal_contactus")}
        />
      </Layout>
    );
  }
}

export default Index;

export const query = graphql`
    query IndexUSQuery {
        hero: allGoogleSheetsHomepage(filter: { block: { eq: "Hero" } }) {
            edges {
                node {
                    id
                    block
                    heroImage
                    title
                    subtitle
                    childrenFile {
                        childImageSharp {
                            gatsbyImageData(width: 2880, placeholder: BLURRED)
                        }
                    }
                }
            }
        }
        backgrounds: allFile(
            filter: { sourceInstanceName: { eq: "backgrounds" } }
        ) {
            nodes {
                relativePath
                name
                childImageSharp {
                    gatsbyImageData(width: 2880, placeholder: BLURRED)
                }
            }
        }
        featuredAlt: allFile(filter: { name: { glob: "CMO4" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        gatsbyImageData(width: 1280, placeholder: BLURRED)
                    }
                }
            }
        }
        featured: allGoogleSheetsHomepage(filter: { block: { eq: "Featured" } }) {
            edges {
                node {
                    heroImage
                    childrenFile {
                        childImageSharp {
                            gatsbyImageData(width: 1280, placeholder: BLURRED)
                        }
                    }
                }
            }
        }
        brands: allGoogleSheetsBrands {
            edges {
                node {
                    name
                    website
                    logo
                    childrenFile {
                        childImageSharp {
                            gatsbyImageData(width: 800, placeholder: BLURRED)
                        }
                    }
                }
            }
        }
        triangle: allFile(filter: { name: { eq: "triangle" } }) {
            edges {
                node {
                    id
                    name
                    childImageSharp {
                        gatsbyImageData(width: 2880, placeholder: BLURRED)
                    }
                }
            }
        }
        device: allFile(
            filter: {
                extension: { regex: "/(jpg)/" }
                name: { eq: "audience-app-hero2" }
            }
        ) {
            edges {
                node {
                    id
                    name
                    childImageSharp {
                        gatsbyImageData(width: 2880, placeholder: BLURRED)
                    }
                }
            }
        }
        devices: allFile(
            filter: { extension: { regex: "/(png)/" }, name: { eq: "audience-app" } }
        ) {
            edges {
                node {
                    id
                    name
                    childImageSharp {
                        gatsbyImageData(width: 2880, placeholder: BLURRED)
                    }
                }
            }
        }
        eps_logo: allFile(
            filter: { extension: { regex: "/(png)/" }, name: { eq: "epssimpledark" } }
        ) {
            edges {
                node {
                    id
                    name
                    childImageSharp {
                        gatsbyImageData(width: 2880, placeholder: BLURRED)
                    }
                }
            }
        }
        eps_bg: allFile(
            filter: { extension: { regex: "/(jpg)/" }, name: { eq: "eps" } }
        ) {
            edges {
                node {
                    id
                    name
                    childImageSharp {
                        gatsbyImageData(width: 2880, placeholder: BLURRED)
                    }
                }
            }
        }
        calloutTop: allGoogleSheetsHomepage(
            filter: { block: { eq: "CalloutTop" } }
        ) {
            edges {
                node {
                    id
                    block
                    heroImage
                    title
                    subtitle
                }
            }
        }
        tweetsSection: allGoogleSheetsHomepage(
            filter: { block: { eq: "Tweets" } }
        ) {
            edges {
                node {
                    id
                    block
                    title
                    subtitle
                }
            }
        }
        tweets: allGoogleSheetsTweets {
            edges {
                node {
                    id
                    tweetId
                }
            }
        }
        cio: allGoogleSheetsBrands(filter: { audience: { eq: "CIO" } }) {
            edges {
                node {
                    name
                    audience
                    title
                    subtitle
                    website
                    logo
                    childrenFile {
                        childImageSharp {
                            gatsbyImageData(width: 200, placeholder: BLURRED)
                        }
                    }
                }
            }
        }
        ciso: allGoogleSheetsBrands(filter: { audience: { eq: "CISO" } }) {
            edges {
                node {
                    name
                    audience
                    title
                    subtitle
                    website
                    logo
                    childrenFile {
                        childImageSharp {
                            gatsbyImageData(width: 200, placeholder: BLURRED)
                        }
                    }
                }
            }
        }
        cfo: allGoogleSheetsBrands(filter: { audience: { eq: "CFO" } }) {
            edges {
                node {
                    name
                    audience
                    title
                    subtitle
                    website
                    logo
                    childrenFile {
                        childImageSharp {
                            gatsbyImageData(width: 200, placeholder: BLURRED)
                        }
                    }
                }
            }
        }
        cmo: allGoogleSheetsBrands(filter: { audience: { eq: "CMO" } }) {
            edges {
                node {
                    name
                    audience
                    title
                    subtitle
                    website
                    logo
                    childrenFile {
                        childImageSharp {
                            gatsbyImageData(width: 200, placeholder: BLURRED)
                        }
                    }
                }
            }
        }
        partners_data: allGoogleSheetsPartnersData(
            limit: 6
            sort: { fields: name }
            filter: { logo: { ne: "logo" } }
        ) {
            edges {
                node {
                    category
                    city
                    name
                    logo
                    website
                    email
                    description
                    childrenFile {
                        childImageSharp {
                            gatsbyImageData(width: 400, placeholder: BLURRED)
                        }
                    }
                }
            }
        }
        about: aboutJson {
            mapSection {
                title
                copy
                video
                cta_text
                cta_link
                cta_text_1
                cta_link_1
                image {
                    childImageSharp {
                        gatsbyImageData(width: 2880, placeholder: BLURRED)
                    }
                }
            }
        }
        offices: allGoogleSheetsOffices {
            edges {
                node {
                    id
                    name
                    type
                    flag
                    location
                    city
                    address
                    latitude
                    longitude
                }
            }
        }
        events2020: allGoogleSheetOverview2020(
            filter: { calendar: { eq: true } }
            sort: { order: DESC, fields: date }
        ) {
            totalCount
            edges {
                node {
                    id
                    brand
                    region
                    country
                    events
                    eventTitle
                    eventType
                    eventTime
                    audience
                    industry
                    panel
                    stateCity
                    date
                    eventSite
                    twitter
                    image
                    webinarLink
                }
            }
        }
        events2021: allGoogleSheetOverview2021(
            filter: { calendar: { eq: true } }
            sort: { order: DESC, fields: date }
        ) {
            totalCount
            edges {
                node {
                    id
                    brand
                    region
                    country
                    events
                    eventTitle
                    eventType
                    eventTime
                    audience
                    industry
                    panel
                    stateCity
                    date
                    eventSite
                    twitter
                    image
                    webinarLink
                }
            }
        }
        events2022: allGoogleSheetOverview2022(
            filter: { calendar: { eq: true } }
            sort: { order: DESC, fields: date }
        ) {
            totalCount
            edges {
                node {
                    id
                    brand
                    region
                    country
                    events
                    eventTitle
                    eventType
                    eventTime
                    audience
                    industry
                    panel
                    stateCity
                    date
                    eventSite
                    twitter
                    image
                    webinarLink
                }
            }
        }
        events2023: allGoogleSheetOverview2023(
            filter: { calendar: { eq: true } }
            sort: { order: DESC, fields: date }
        ) {
            totalCount
            edges {
                node {
                    id
                    brand
                    region
                    country
                    events
                    eventTitle
                    eventType
                    eventTime
                    audience
                    industry
                    panel
                    stateCity
                    date
                    eventSite
                    twitter
                    image
                    webinarLink
                }
            }
        }
        events2024: allGoogleSheetOverview2024(
            filter: { calendar: { eq: true } }
            sort: { order: DESC, fields: date }
        ) {
            totalCount
            edges {
                node {
                    id
                    brand
                    region
                    country
                    events
                    eventTitle
                    eventType
                    eventTime
                    audience
                    industry
                    stateCity
                    date
                    eventSite
                    twitter
                    image
                    webinarLink
                }
            }
        }
        events2025: allGoogleSheetOverview2025(
            filter: { calendar: { eq: true } }
            sort: { order: DESC, fields: date }
        ) {
            totalCount
            edges {
                node {
                    id
                    brand
                    region
                    country
                    events
                    eventTitle
                    eventType
                    eventTime
                    audience
                    industry
                    stateCity
                    date
                    eventSite
                    twitter
                    image
                    webinarLink
                }
            }
        }
    }
`;
